import "firebase/auth";
import "firebase/firestore";
import firebase from "gatsby-plugin-firebase";

import FirebaseContext, { withFirebase } from "./context";
import { setSavedBlogs, setSavedRecipes } from "@util/helper";

export interface FirebaseProps {
  firebase: firebase.app.App;
}
export interface DocumentRefProps {
  refString: string;
  uid?: string;
}

export interface FirestoreDataProps {
  firestore: firebase.firestore.Firestore;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  name?: string | null;
  uid: string;
}

const firestore = firebase.firestore();
export const documentRef = ({ refString, uid }: DocumentRefProps) => firestore.collection(refString).doc(uid);

export function setDataToFirestore(dataProps: FirestoreDataProps) {
  const { email, name, uid, firestore } = dataProps;
  const docRef = firestore.collection("users").doc(uid);
  const setData = {
    name,
    email,
    uid,
  };

  docRef
    .set(setData, { merge: true })
    .then(() => {
      docRef.get().then(doc => {
        const data = doc.data();
        if (data) {
          if (data.savedRecipes) {
            setSavedRecipes(data.savedRecipes);
            setSavedBlogs(data.savedBlogs);
          }
        }
      });
    })
    .catch((error: Error) => console.log({ error }));
}

export default firebase;
export { FirebaseContext, withFirebase, firestore };
