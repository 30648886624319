import create from "zustand";

import { UserProps } from "@util/types";
import { persist, devtools } from "zustand/middleware";
import { Checkout } from "shopify-storefront-api-typings";

import { SearchParams } from "./types";

interface State {
  isLoggedIn: boolean;
  authMenuOpen: boolean;
  searchFilters: string[];
  logIn: () => void;
  logOut: () => void;
  savedRecipes?: string[];
  savedBlogs: string[];
  user?: UserProps | null | undefined;
  checkout?: Checkout;
  checkoutOpen?: boolean;
  currentSearch?: string;
  headerSearch?: string;
  createAccountFormOpen?: boolean;
  searchParams: SearchParams;
  setHasSearched: (hasSearched: boolean) => void;
  clearSearchParams: () => void;
}

export const defaultScreenWidth = {
  isMobileWidth: false,
  isTabletWidth: false,
  isLaptopWidth: false,
};

export const defaultSearchParams = {
  searchString: "",
  hasSearched: false,
  globalSearch: false,
};

export const defaultState = {
  isLoggedIn: false,
  authMenuOpen: false,
  searchFilters: [],
  savedBlogs: [],
  savedRecipes: [],
  checkoutOpen: false,
  currentSearch: "",
  headerSearch: "",
  createAccountFormOpen: false,
};

export const useStore = create(
  devtools(
    persist<State>(
      set => ({
        isLoggedIn: defaultState.isLoggedIn,
        authMenuOpen: defaultState.authMenuOpen,
        checkoutOpen: defaultState.checkoutOpen,
        logIn: () => set({ isLoggedIn: true }),
        logOut: () => set({ isLoggedIn: false }),
        savedBlogs: defaultState.savedBlogs,
        savedRecipes: defaultState.savedRecipes,
        currentSearch: defaultState.currentSearch,
        headerSearch: defaultState.headerSearch,
        createAccountFormOpen: defaultState.createAccountFormOpen,
        searchFilters: [],
        searchParams: defaultSearchParams,
        setHasSearched: hasSearched => set(prev => ({ searchParams: { ...prev.searchParams, hasSearched } })),
        clearSearchParams: () => set({ searchParams: defaultSearchParams, searchFilters: [] }),
      }),
      {
        name: "langbein-store",
        getStorage: () => sessionStorage,
      },
    ),
  ),
);
