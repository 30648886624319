import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Dropdown } from "react-dropdown-now";
import "react-dropdown-now/style.css";
import { Query, SanityProduct } from "@graphql-types";
import { A, Container, GridContainer, P } from "@util/standard";
import ProductCard from "./productCard";
import { icons, TABLET_BREAKPOINT } from "@util/constants";
import { graphql, useStaticQuery } from "gatsby";

interface FilterDropDownProps {
  text: string;
  margin?: string;
}

const StyledDropdown = styled(Dropdown)`
  width: 23%;
  color: black;

  .rdn-control {
    width: 100%;
    border: 1px solid black;
    padding-left: 20px;
  }

  .is-selected {
    width: 100%;
    background: transparent;
  }

  .rdn-drop {
    overflow-y: visible;
    max-height: none;
    border: 1px solid black;
    z-index: 4;
    width: 100%;
  }

  .rdn-control-arrow-icon {
    border-style: none;
    background: url(${icons.arrow});
    width: 14px;
    height: 9px;
    margin-right: 5px;
  }
  .rdn-drop-menu-option {
    color: black;
    padding: 0 0 0 20px;
    font-weight: 600 !important;

    &:hover {
      background: transparent;
    }
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
  }
`;

function PreviewGrid() {
  const [filterBestSellingOn, setFilterBestSellingOn] = useState(false);
  const [filteredProducts, setfilteredProducts] = useState([]);
  const [category, setCategory] = useState<string>();
  const { allSanityProduct }: Query = useStaticQuery(graphql`
    query productQuery {
      allSanityProduct {
        nodes {
          ...sanityProduct
        }
      }
    }
  `);
  if (allSanityProduct == null || allSanityProduct.nodes == null) {
    return null;
  }

  const FilterButton = ({ text, margin }: FilterDropDownProps) => {
    return (
      <Container margin={margin}>
        <P width="100%" margin="7px 0px">
          {text}
        </P>
      </Container>
    );
  };

  const options = [
    { value: "", label: "None", view: <FilterButton text="None" margin="20px 0 0 0" /> },
    {
      value: "Best Selling",
      label: "Best Selling",
      view: <FilterButton text="Best Selling" />,
    },
    {
      value: "priceLow",
      label: "Price, low to high",
      view: <FilterButton text="Price, low to high" />,
    },
    {
      value: "priceHigh",
      invert: true,
      label: "Price, high to low",
      view: <FilterButton text="Price, high to low" margin="0 0 20px 0" />,
    },
    // {
    //   value: "price",
    //   label: "Date, old to new",
    //   view: <FilterButton text="Date, old to new" />,
    // },
    // {
    //   value: "Date New",
    //   invert: false,
    //   label: "Date, new to old",
    //   view: <FilterButton text="Date, new to old" margin="0 0 20px 0" />,
    // },
  ];

  useEffect(() => {
    if (allSanityProduct && allSanityProduct.nodes) {
      setfilteredProducts(allSanityProduct.nodes);
    }
  }, []);

  const handleChange = (category: string) => {
    setCategory(category);

    if (category === "Best Selling") {
      setFilterBestSellingOn(true);
      return;
    }
    if (category === "priceLow") {
      setFilterBestSellingOn(false);

      const nodes = allSanityProduct.nodes;
      const sortedProducts = nodes.sort(function (a, b) {
        if (a.price == null || b.price == null || a.salePrice == null || b.salePrice == null) {
          return 0;
        }
        if (a.onSale) {
          if (a.salePrice > b.price) return 1;
          if (a.salePrice < b.price) return -1;
          return 0;
        }
        if (b.onSale) {
          if (a.price > b.salePrice) return 1;
          if (a.price < b.salePrice) return -1;
          return 0;
        }
        if (a.onSale && b.onSale) {
          if (a.salePrice > b.salePrice) return 1;
          if (a.salePrice < b.salePrice) return -1;
          return 0;
        } else {
          if (a.price > b.price) return 1;
          if (a.price < b.price) return -1;
          return 0;
        }
      });
      setfilteredProducts(sortedProducts);
      return;
    }

    if (category === "priceHigh") {
      setFilterBestSellingOn(false);
      const nodes = allSanityProduct.nodes;
      const sortedProducts = nodes.sort(function (a, b) {
        if (a.price == null || b.price == null || a.salePrice == null || b.salePrice == null) {
          return 0;
        }
        if (a.onSale) {
          if (a.salePrice < b.price) return 1;
          if (a.salePrice > b.price) return -1;
          return 0;
        }
        if (b.onSale) {
          if (a.price < b.salePrice) return 1;
          if (a.price > b.salePrice) return -1;
          return 0;
        }
        if (a.onSale && b.onSale) {
          if (a.salePrice < b.salePrice) return 1;
          if (a.salePrice > b.salePrice) return -1;
          return 0;
        } else {
          if (a.price < b.price) return 1;
          if (a.price > b.price) return -1;
          return 0;
        }
      });
      setfilteredProducts(sortedProducts);

      return;
    } else {
      setFilterBestSellingOn(false);
      setfilteredProducts(allSanityProduct.nodes);
    }
    return;
  };

  return (
    <div>
      <Container width="100%" flexDirection="column">
        <StyledDropdown
          placeholder="Sort"
          options={options}
          value="Sort"
          onChange={value => {
            handleChange(value.value);
          }}
        />

        <GridContainer columnGap="1%" repeat={3} rowGap={60} width="100%" margin="40px 0 0 0" mobileRepeat={2} >
          {filteredProducts &&
            filteredProducts.map(product => {
              if (product == null) {
                return null;
              }
              if (filterBestSellingOn && !product.bestSelling) {
                return null;
              }

              return (
                <Container key={product._id}>
                  <ProductCard data={product} />
                </Container>
              );
            })}
        </GridContainer>
      </Container>
    </div>
  );
}

export default PreviewGrid;
