export const BUTTON_HEIGHT = 46;
export const HEADER_HEIGHT = 84;

// screen sizes
export const SMALLER_MOBILE = 400;
export const MOBILE_BREAKPOINT = 600;
export const IPAD_BREAKPOINT = 768;
export const TABLET_BREAKPOINT = 1000;
export const IPAD_PRO_BREAKPOINT = 1025;
export const SMALL_LAPTOP_BREAKPOINT = 1280;
export const LAPTOP_BREAKPOINT = 1441;
export const TWENTY_INCH_SCREEN = 1600;
export const IMAC_BREAKPOINT = 1921;
export const DEFAULT_OFFSET = 120;
export const MOBILE_OFFSET = 60;

export const colors = {
  transparent: "transparent",
  white: "#fff",
  black: "#000",
  mossGreen: "#66795A",
  pink: "#D3AAA4",
  darkerGreen: "#214A21",
  wanakaBlue: "#A5BFBD",
};

export const colorFilter = {
  base: "invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(0%)",
  mossGreen: "invert(47%) sepia(6%) saturate(1764%) hue-rotate(53deg) brightness(72%) contrast(85%)",
  darkerGreen: "invert(24%) sepia(42%) saturate(771%) hue-rotate(71deg) brightness(22%) contrast(87%)",
};

export const fontWeights = {
  normal: 400,
  semibold: 600,
  bold: 700,
};

export const PRIMARY_COLOR = colors.mossGreen;
export const SECONDARY_COLOR = colors.black;

export const fontFamilies = {
  proximaNova: "proxima-nova",
  feijoa: "feijoa",
};

export const fontSizes = {
  p: { default: 15, mobile: 15 },
  h1: { default: 65, mobile: 45 },
  h2: { default: 40, mobile: 33 },
  h3: { default: 30, mobile: 28 },
  h4: { default: 22, mobile: 22 },
  span: { default: 16, mobile: 12 },
};

export const colorsRGB = {
  black: (opacity?: number) => `rgba(0, 0, 0, ${opacity ?? "0.2"})`,
  white: (opacity?: number) => `rgba(255, 255, 255, ${opacity ?? "0.3"})`,
  darkerGreen: (opacity?: number) => `rgba(33, 74, 33, ${opacity ?? "1"})`,
  mossGreen: (opacity?: number) => `rgba(102, 121, 90, ${opacity ?? "1"})`,
};

export const pages = {
  home: "/",
  aboutUs: "/about-us",
  contactUs: "/contact-us",
  search: "/search",
  notFound: "/404",
};

// define paths to asset
export const assets = {
  logo: require("@static/assets/logo.png").default,
};

export const icons = {
  search: require("@static/icons/search.svg").default,
  searchWhite: require("@static/icons/searchWhite.svg").default,
  login: require("@static/icons/login.svg").default,
  cart: require("@static/icons/cart.svg").default,
  hamburger: require("@static/icons/hamburger.svg").default,
  hamburgerWhite: require("@static/icons/hamburgerWhite.svg").default,
  close: require("@static/icons/close.svg").default,
  closeDark: require("@static/icons/closeDark.svg").default,
  playButton: require("@static/icons/playButton.svg").default,
  prepTime: require("@static/icons/prepTime.svg").default,
  cookTime: require("@static/icons/cookTime.svg").default,
  servings: require("@static/icons/servings.svg").default,
  facebookNoBorder: require("@static/icons/facebookNoBorder.svg").default,
  email: require("@static/icons/email.svg").default,
  pinterest: require("@static/icons/pinterest.svg").default,
  twitter: require("@static/icons/twitter.svg").default,
  print: require("@static/icons/print.svg").default,
  video: require("@static/icons/video.svg").default,
  unchecked: require("@static/icons/unchecked.svg").default,
  checked: require("@static/icons/checked.svg").default,
  uncheckedDark: require("@static/icons/uncheckedDark.svg").default,
  checkedDark: require("@static/icons/checkedDark.svg").default,
  googleSignIn: require("@static/icons/googleSignIn.svg").default,
  facebookSignIn: require("@static/icons/facebookSignIn.svg").default,
  unSaved: require("@static/icons/unSaved.svg").default,
  unSavedDark: require("@static/icons/unSavedDark.svg").default,
  saved: require("@static/icons/saved.svg").default,
  arrow: require("@static/icons/arrow.svg").default,
  categoryClose: require("@static/icons/categoryClose.svg").default,
  loginWhite: require("@static/icons/loginWhite.svg").default,
  cartWhite: require("@static/icons/cartWhite.svg").default,
  arrowWhite: require("@static/icons/arrowWhite.svg").default,
};

export const buttonStyle = {
  bordered: {
    bg: `${colors.transparent}`,
    text: `${colors.darkerGreen}`,
    border: `${colors.mossGreen}`,
    hoverBg: `${colors.mossGreen}`,
    hoverBorder: `${colors.mossGreen}`,
    hoverText: `${colors.white}`,
    textDecoration: `none`,
    fontWeight: `${fontWeights.semibold}`,
  },
  borderedWhite: {
    bg: `${colors.transparent}`,
    text: `${colors.white}`,
    border: `${colors.white}`,
    hoverBg: `${colors.white}`,
    hoverBorder: `${colors.white}`,
    hoverText: `${colors.mossGreen}`,
    textDecoration: `none`,
    fontWeight: `${fontWeights.semibold}`,
  },
  block: {
    bg: `${colors.transparent}`,
    text: `${colors.white}`,
    border: `${colors.mossGreen}`,
    hoverBg: `${colors.mossGreen}`,
    hoverBorder: `${colors.mossGreen}`,
    hoverText: `${colors.mossGreen}`,
    textDecoration: `none`,
    fontWeight: `${fontWeights.semibold}`,
    reversed: true,
  },
  blockWhite: {
    bg: `${colors.transparent}`,
    text: `${colors.darkerGreen}`,
    border: `${colors.white}`,
    hoverBg: `${colors.white}`,
    hoverBorder: `${colors.white}`,
    hoverText: `${colors.white}`,
    textDecoration: `none`,
    fontWeight: `${fontWeights.semibold}`,
    reversed: true,
  },

  text: {
    bg: `${colors.transparent}`,
    text: `${colors.mossGreen}`,
    border: `${colors.transparent}`,
    hoverBg: `${colors.transparent}`,
    hoverBorder: `${colors.transparent}`,
    hoverText: `${colorsRGB.mossGreen(0.6)}`,
    textDecoration: `underline`,
    fontWeight: `${fontWeights.semibold}`,
  },
  textWhite: {
    bg: `${colors.transparent}`,
    text: `${colors.white}`,
    border: `${colors.transparent}`,
    hoverBg: `${colors.transparent}`,
    hoverBorder: `${colors.transparent}`,
    hoverText: `${colorsRGB.white(0.6)}`,
    textDecoration: `underline`,
    fontWeight: `${fontWeights.semibold}`,
  },
  textBlack: {
    bg: `${colors.transparent}`,
    text: `${colors.black}`,
    border: `${colors.transparent}`,
    hoverBg: `${colors.transparent}`,
    hoverBorder: `${colors.transparent}`,
    hoverText: `${colorsRGB.black(0.6)}`,
    textDecoration: `underline`,
    fontWeight: `${fontWeights.semibold}`,
  },
};

export const animationTypes = {
  fadeIn: {
    to: { opacity: 1 },
    from: { opacity: 0 },
  },
  fadeOut: {
    to: { opacity: 0 },
    from: { opacity: 1 },
  },
  fadeInFromLeft: {
    to: { transform: "translateX(0px)", opacity: 1 },
    from: { transform: "translateX(-200px)", opacity: 0 },
  },
  fadeInFromRight: {
    to: { transform: "translateX(0px)", opacity: 1 },
    from: { transform: "translateX(200px)", opacity: 0 },
  },
  fadeInFromTop: {
    to: { transform: "translateY(0px)", opacity: 1 },
    from: { transform: "translateY(-200px)", opacity: 0 },
  },
  fadeInFromBottom: {
    to: { transform: "translateY(0px)", opacity: 1 },
    from: { transform: "translateY(200px)", opacity: 0 },
  },
  rotateLeft: {
    to: { transform: "rotateZ(0deg)" },
    from: { transform: "rotateZ(360deg)" },
  },
  rotateRight: {
    to: { transform: "rotateZ(360deg)" },
    from: { transform: "rotateZ(0deg)" },
  },
  slideInFromRight: (distance: string, opacity?: number) => {
    return {
      to: { transform: "translateX(0px)", opacity: 1 },
      from: { transform: `translateX(${distance})`, opacity: opacity !== 1 ? opacity : 1 },
    };
  },
  slideInFromTop: (distance: string, opacity?: number) => {
    return {
      to: { transform: "translateY(0px)", opacity: 1 },
      from: { transform: `translateY(${distance})`, opacity: opacity !== 1 ? opacity : 1 },
    };
  },
  noAnimation: {
    to: { opacity: 1 },
    from: { opacity: 1 },
  },
};

export const gradients = {
  gradientFromBottom: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%)",
  gradientFromTop:
    "linear-gradient(180deg, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.14) 49.94%, rgba(0, 0, 0, 0.35) 100%);",
  heroGradient: "linear-gradient(180deg, rgba(0, 0, 0, 0.6) 10.42%, rgba(0, 0, 0, 0.2) 34.38%, rgba(0, 0, 0, 0) 100%);",
};
