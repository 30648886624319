import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Vimeo from "@u-wave/react-vimeo";
import Player from "@vimeo/player";
import { colors, colorsRGB, icons, IPAD_PRO_BREAKPOINT, TABLET_BREAKPOINT } from "@util/constants";
import { Container } from "@util/standard";
import { Image } from ".";
import { GatsbyImageProps } from "gatsby-plugin-image";
import { SanityMetaImage } from "@graphql-types";

interface Props {
  id: string;
  url: string;
  pause?: boolean;
  play?: boolean;
  volume?: boolean;
  currentTime?: number | null | undefined;
  height?: string;
  ipadProHeight?: string;
  ipadProWidth?: string;
  tabletWidth?: string;
  width?: string;
  minWidth?: string;
  minHeight?: string;
  foregroundOpen?: boolean;
  autoPlayOnLoad?: boolean;
  isVisible?: boolean;
  hidePlayButton?: boolean;
  background?: boolean;
  useThumbnail?: boolean;
  thumbnail?: SanityMetaImage;
  controls?: boolean;
}

const VideoWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
`;

const PlayButton = styled.img`
  position: absolute;
  z-index: 101;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  &:hover {
    width: 80px;
    height: 80px;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 40px;
    height: 40px;
  }
`;

const StyledVimeo = styled(Vimeo)<{
  foregroundOpen?: boolean;
  ipadProHeight?: string;
  ipadProWidth?: string;
  tabletWidth?: string;
}>`
  z-index: 2;

  iframe {
    width: ${props => props.width ?? "100%"};
    height: ${props => props.height ?? "100%"};
    border: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    ${({ foregroundOpen }) => foregroundOpen && `min-width: 0px`}

    @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
      ${({ ipadProHeight }) => ipadProHeight && `height: ${ipadProHeight};`};
      ${({ ipadProWidth }) => ipadProWidth && `width: ${ipadProWidth};`};
    }

    @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
      ${({ tabletWidth }) => tabletWidth && `width: ${tabletWidth};`};
    }
  }
`;

function Video(props: Props) {
  const {
    id,
    url,
    play,
    pause,
    currentTime,
    controls,
    volume,
    height,
    ipadProHeight,
    ipadProWidth,
    tabletWidth,
    background,
    width,
    minHeight,
    minWidth,
    hidePlayButton,
    foregroundOpen,
    autoPlayOnLoad,
    isVisible,
    useThumbnail,
    thumbnail,
    isPopUp,
  } = props;

  const [autoPlay, setAutoplay] = useState(autoPlayOnLoad);
  const [playButtonVisible, setPlayButtonVisible] = useState(true);
  const [thumbnailVisible, setThumbnailVisible] = useState(true);

  const playerRef = useRef<Player>();

  function onLoad() {
    if (autoPlay) {
      playerRef.current && playerRef.current.play();
    }
  }

  function onReady(player: Player) {
    playerRef.current = player;
  }

  useEffect(() => {
    if (thumbnail == null || thumbnail.asset == null) {
      setThumbnailVisible(false);
    }
  });

  useEffect(() => {
    if (isVisible) {
      playerRef.current ? playerRef.current.play() : setAutoplay(true);
    } else {
      playerRef.current && playerRef.current.pause();
    }
  }, [isVisible]);

  useEffect(() => {
    if (playerRef.current && foregroundOpen) {
      playerRef.current.setCurrentTime(0);
    }
  }, [foregroundOpen]);

  const handlePlayButtonClick = () => {
    playerRef.current && playerRef.current.play();
    setPlayButtonVisible(false);
  };

  return (
    <>
      {!hidePlayButton && playButtonVisible && !useThumbnail && (
        <Container width="100%" height="100%">
          <PlayButton src={icons.playButton} onClick={() => handlePlayButtonClick()} />
        </Container>
      )}
      {thumbnail && thumbnail.asset && useThumbnail && thumbnailVisible && (
        <Container width="100%" height="100%" position="relative">
          <Image image={thumbnail.asset.gatsbyImageData} objectFit="cover" />
          {!hidePlayButton && <PlayButton src={icons.playButton} onClick={() => setThumbnailVisible(false)} />}
          <Container position="absolute" width="100%" height="100%" backgroundRGBColor={colorsRGB.black(0.25)} />
        </Container>
      )}
      {!thumbnailVisible && (
        <VideoWrapper>
          <StyledVimeo
            video={url}
            autoplay={!thumbnailVisible}
            background={background ?? false}
            muted={!volume}
            height={height}
            ipadProWidth={ipadProWidth}
            ipadProHeight={ipadProHeight}
            tabletWidth={tabletWidth}
            width={width}
            minHeight={minHeight}
            minWidth={minWidth}
            loop
            volume={volume ? 1 : 0}
            controls={controls ?? false}
            onLoaded={onLoad}
            onReady={onReady}
            foregroundOpen={foregroundOpen}
          />
        </VideoWrapper>
      )}
    </>
  );
}

export default Video;
