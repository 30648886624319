import React from "react";

import { useStore } from "@state/store";
import { Container, P } from "@util/standard";

const CartSuccessToast = () => {
  return (
    <Container margin="5px 0 10px 0" flexDirection="column" width="max-content">
      <P margin="10px 0">Added to cart</P>
      <P noMargin underlined onClick={() => useStore.setState({ checkoutOpen: true })}>
        Go to cart
      </P>
    </Container>
  );
};

export default CartSuccessToast;
