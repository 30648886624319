import React, { ReactNode, useEffect, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import firebase from "gatsby-plugin-firebase";
import { ToastContainer } from "react-toastify";

import Header from "./header";
import Footer from "./footer";
import { colors, fontSizes, TABLET_BREAKPOINT, MOBILE_BREAKPOINT, fontFamilies } from "@util/constants";
import { useStore, defaultState } from "@state/store";
import { FirebaseContext } from "@lib/firebase";
import { createCheckout } from "@util/shopifyHelper";
import { isBrowser } from "@util/helper";
import "react-toastify/dist/ReactToastify.css";
import { Container } from "@util/standard";
import { useCheckScreenWidth } from "@util/hooks";

interface Props {
  children: ReactNode;
}

const GlobalStyle = createGlobalStyle`
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-font-kerning: normal;
    font-kerning: normal;
    margin: 0px;
    font-family: ${fontFamilies.proximaNova}, monospace;
    font-size: ${fontSizes.p}px;
    
  }

  color: ${colors.white};
  h1 {
    font-size: ${fontSizes.h1.default}px;
    font-family: ${fontFamilies.feijoa};
    line-height: 72px;
    margin: 0px;
    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      font-size: ${fontSizes.h1.mobile}px;
      line-height: ${fontSizes.h1.mobile * 1.2}px;
    }
  }

  h2 {
    font-size: ${fontSizes.h2.default}px;
    line-height: 48px;
    font-family: ${fontFamilies.feijoa};
    margin: 0px;

    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      font-size: ${fontSizes.h2.mobile}px;
      line-height: ${fontSizes.h2.mobile * 1.2}px;
    }
  }

  h3 {
    font-size: ${fontSizes.h3.default}px;
    font-family: ${fontFamilies.feijoa};
    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      font-size: ${fontSizes.h3.mobile}px;
      line-height: ${fontSizes.h3.mobile * 1.2}px;
    }
  }

  h6 {
    font-size: 18px;
    font-weight: 500;
  }

  p {
    font-family: ${fontFamilies.proximaNova}, monospace;
    line-height: 18px;
    font-size: ${fontSizes.p.default}px;
    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      font-size: ${fontSizes.p.default}px;
      line-height: ${fontSizes.p.mobile * 1.2}px;
    }
  }

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-kerning: normal;
  font-kerning: normal;

  input,
  textarea,
  button,
  select,
  a, img, div {
      -webkit-tap-highlight-color: rgba(0,0,0,0);
  }

`;

const Main = styled.main<{}>`
  /* overflow-x: hidden; */
`;

const StyledToastContainer = styled(ToastContainer)`
  max-width: 210px;
  .Toastify__toast-theme--colored.Toastify__toast--success {
    background: ${colors.darkerGreen};
    top: 75px;
  }

  .Toastify__toast-icon {
    display: none;
  }
`;

function Layout(props: Props) {
  const { children } = props;
  const { isMobileWidth } = useCheckScreenWidth();

  const pageLocationCheck = () => {
    if (isBrowser()) {
      const isWhiteBackgroundPage =
        window.location.href.includes("products") ||
        (window.location.href.includes("recipes") &&
          window.location.pathname !== "/recipes/" &&
          window.location.pathname !== "/recipes" &&
          !isMobileWidth) ||
        window.location.href.includes("account") ||
        window.location.href.includes("faq") ||
        window.location.href.includes("contact") ||
        window.location.href.includes("conversions") ||
        window.location.href.includes("my-saved") ||
        window.location.href.includes("privacy-policy") ||
        window.location.href.includes("search-results") ||
        window.location.href.includes("terms-and-conditions");

      return isWhiteBackgroundPage;
    }
    return false;
  };

  const isHomePage = () => {
    if (isBrowser()) {
      const homePageCheck = window.location.pathname === "/";
      return homePageCheck;
    }
    return false;
  };

  const [useFirebase, setUseFirebase] = useState<firebase.app.App | null>(null);
  const store = useStore();
  useEffect(() => {
    const app = firebase.app();
    setUseFirebase(app);
    if (store.checkout == null) {
      createCheckout();
    }
  }, []);

  return (
    <FirebaseContext.Provider value={useFirebase}>
      <GlobalStyle />
      <Header isHomePage={isHomePage()} onWhiteBackground={pageLocationCheck()} />
      <Main>{children}</Main>
      <StyledToastContainer theme="colored" />
      <Footer />
    </FirebaseContext.Provider>
  );
}

export default Layout;
