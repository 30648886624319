import React, { useState } from "react";
import styled from "styled-components";
import { Link as GatsbyLink } from "gatsby";

import { ButtonTheme, Color, Dimensions, FontWeights } from "@util/types";
import {
  buttonStyle,
  colorFilter,
  fontFamilies,
  fontSizes,
  fontWeights,
  icons,
  MOBILE_BREAKPOINT,
} from "@util/constants";
import Loading from "./loading";
import { Container, GiveMeSomeSpace, P, A } from "@util/standard";

const StyledButton = styled.button<{
  theme: ButtonTheme;
  dimensions?: Dimensions;
  mobileDimensions?: Dimensions;
  disableHover?: boolean;
  mobileMargin?: string;
  margin?: string;
  minHeight?: number;
  width?: string;
  padding?: string;
  fontSize?: number;
  borderThickness?: string;
  borderOverride?: string;
  fontWeight?: number;
  borderRadius?: string;
}>`
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px;`}
  font-family: ${fontFamilies.proximaNova};
  text-align: center;
  cursor: pointer;
  user-select: none;
  ${({ margin }) => margin && `margin: ${margin};`}
  padding: ${props => (props.padding ? props.padding : `10px 35px`)};
  ${({ width }) => width && `width: ${width};`}
  height: ${props => props.dimensions?.height ?? `auto`};
  color: ${props => props.theme.text};
  border: 2px solid ${props => props.theme.border};
  border: ${props => props.borderThickness} solid ${props => props.theme.border};
  border: ${props => props.borderOverride};
  ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius};`};
  background-color: ${props => props.theme.bg};
  text-decoration: ${props => props.theme.textDecoration};
  font-weight: ${fontWeights.semibold};

  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight};`};
  position: relative;
  transition: all 0.1s linear;
  z-index: 1;

  &:focus {
    outline: 0;
  }

  ${({ disableHover, theme }) =>
    !disableHover &&
    `&:hover {
      outline: 0;
      border-color: ${theme.hoverBorder};
      color: ${theme.hoverText};

      div {        
        width: ${theme.reversed ? `0%;` : `101%;`};
      }

      img {
        
        filter: ${theme.hoverBg === "#fff" ? `${colorFilter.darkerGreen};` : `brightness(10000%);`}
        
      }
  }`}

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    ${({ mobileDimensions }) => mobileDimensions && `width:${mobileDimensions}`}
    ${({ mobileMargin }) => mobileMargin && `margin: ${mobileMargin};`}
    padding: ${props => (props.padding ? props.padding : `5px 30px`)};
  }
  height: 42px;
  ${({ minHeight }) => minHeight && `min-height: ${minHeight}px;`}
`;

const LoadingContainer = styled(Container)`
  flex: 1;
  justify-content: center;
`;

const Icon = styled.img`
  transform: translateY(2px);
  margin-right: 10px;
  height: 15px;
`;

const Background = styled.div<{ theme?: ButtonTheme; backgroundOveride?: Color; }>`
  ${({ theme }) => theme && `background: ${theme.hoverBg};`};
  ${({ theme }) => (theme.reversed ? `width: 101.5%;` : `width: 0%;`)};
  height: calc(100% + 2.5px);
  top: -1px;
  left: -1px;
  position: absolute;
  z-index: -1;
  transition: all 0.3s ease-in;
`;

interface Props {
  theme?: ButtonTheme;
  disabled?: boolean;
  borderOverride?: string;
  className?: string;
  dimensions?: Dimensions;
  mobileDimensions?: Dimensions;
  minHeight?: number;
  text: string;
  margin?: string;
  padding?: string;
  disableHoverEffect?: boolean;
  bgOverride?: Color;
  mobileMargin?: string;
  borderThickness?: string;
  onClick?: (args?: any) => void;
  loading?: boolean;
  type?: "button" | "submit" | "reset";
  linkTo?: string;
  linkToNewWindow?: string;
  width?: string;
  icon?: string;
  fontWeight?: FontWeights;
  borderRadius?: string;
}

const Button = ({
  theme = "border",
  bgOverride,
  borderOverride,
  disabled,
  dimensions,
  width,
  mobileDimensions,
  text,
  margin,
  disableHoverEffect,
  onClick,
  mobileMargin,
  loading,
  minHeight,
  padding,
  type,
  borderThickness,
  linkTo,
  linkToNewWindow,
  icon,
  fontWeight,
  borderRadius,
}: Props) => {
  const Loader = () => (
    <LoadingContainer>
      <>
        <Loading />
        <GiveMeSomeSpace space={3} />
        <P noMargin color="white">
          Loading..
        </P>
      </>
    </LoadingContainer>
  );

  const handleOnClick = () => {
    if (loading) {
      return;
    }
    if (onClick) {
      onClick();
    }
  };

  const RenderedButton = ({ externalLink }: { externalLink?: string | undefined; }) => (
    <StyledButton
      theme={buttonStyle[theme]}
      dimensions={dimensions}
      width={width}
      borderOverride={borderOverride}
      disabled={disabled}
      onClick={handleOnClick}
      margin={margin}
      borderThickness={borderThickness ?? "1.5px"}
      padding={padding}
      mobileDimensions={mobileDimensions}
      disableHover={loading || disableHoverEffect}
      mobileMargin={mobileMargin}
      minHeight={minHeight}
      type={type}
      fontWeight={fontWeight}
      borderRadius={borderRadius}
    >
      {icon && <Icon src={icon} />}

      {loading ? (
        <Loader />
      ) : externalLink ? (
        <A href={onClick ? externalLink : null} onClick={onClick} hoverColor="white" target="_blank" rel="noreferrer">
          {text}
        </A>
      ) : (
        text
      )}
      <Background theme={buttonStyle[theme]} backgroundOveride={bgOverride} />
    </StyledButton>
  );

  if (onClick) {
    <Container onClick={onClick}>
      <RenderedButton />
    </Container>;
  }

  if (linkTo) {
    return (
      <GatsbyLink to={linkTo}>
        <RenderedButton />
      </GatsbyLink>
    );
  }

  return <RenderedButton externalLink={linkToNewWindow} />;
};

export default Button;
