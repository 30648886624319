import { Dispatch, SetStateAction } from "react";
import { animationTypes, assets, buttonStyle, colors, colorsRGB, fontFamilies, fontWeights } from "./constants";

import {
  Maybe,
  SanityBlog,
  SanityBlogPreview,
  SanityBudget,
  SanityCategoryPanelPreview,
  SanityColumn,
  SanityColumnContent,
  SanityContentSlider,
  SanityCtaBanner,
  SanityCtaBlock,
  SanityDifficulty,
  SanityFeedYourLife,
  SanityFeedYourLifeInner,
  SanityFeedYourLifePreview,
  SanityFlavours,
  SanityHero,
  SanityImage,
  SanityIngredient,
  SanityIngredientAmount,
  SanityLearnCategories,
  SanityLearnCategoryPreview,
  SanityLink,
  SanityOccasions,
  SanityProduct,
  SanityProductPreview,
  SanityProductReferenceCta,
  SanityProfilesBlock,
  SanityRecipe,
  SanityRecipeCategories,
  SanityRecipeIngredient,
  SanityRecipePreview,
  SanityRecipeRefWithQuantity,
  SanityRecipeStep,
  SanitySpecialDiets,
  SanitySuggestedBlogs,
  SanityVideo,
} from "@graphql-types";
import { deflateRawSync } from "zlib";

import {
  Image,
  DateTime,
  CountryCode,
  MoneyV2,
  Product,
  SelectedOption,
  UnitPriceMeasurement,
  WeightUnit,
  Attribute,
  Customer,
  MailingAddressConnection,
  OrderConnection,
} from "shopify-storefront-api-typings";

export type Dimensions = {
  width?: string;
  height?: string;
};

export type Asset = keyof typeof assets;
export type Color = keyof typeof colors;
export type ColorRGB = keyof typeof colorsRGB;
export type ButtonTheme = keyof typeof buttonStyle;
export type ButtonType = typeof buttonStyle;
export type FontWeights = keyof typeof fontWeights;
export type AssetType = typeof assets;
export type AnimationType = typeof animationTypes;
export type FontFamily = keyof typeof fontFamilies;

export type SetRegion = Dispatch<
  SetStateAction<{
    iban: string;
    langCode: string;
  }>
>;

export type ScreenWidth = {
  isMobileWidth: boolean;
  isTabletWidth: boolean;
  isLaptopWidth: boolean;
};

export type AnimationDirection = {
  to: {
    transform?: string;
    opacity?: number;
  };
  from: {
    transform?: string;
    opacity?: number;
  };
};

export function isSanityImage(data: any): data is SanityImage {
  return data._type === "metaImage";
}

export function isSanityLink(data: any): data is SanityLink {
  return data._type === "link";
}

export function isSanityCategory(
  data: any,
): data is
  | SanityRecipeCategories
  | SanityOccasions
  | SanityFlavours
  | SanityLearnCategories
  | SanitySpecialDiets
  | SanityDifficulty
  | SanityBudget {
  return (
    data._type === "budget" ||
    data._type === "flavours" ||
    data._type === "difficulty" ||
    data._type === "occasions" ||
    data._type === "recipeCategories" ||
    data._type === "learnCategories" ||
    data._type === "specialDiets"
  );
}

export interface CheckoutLineItemInput {
  customAttributes?: LineItemAttributes[];
  quantity: number;
  variantId: string;
}

export interface LineItemAttributes {
  key: string;
  value: string;
}

export interface Shopify {
  customer: CompleteCustomer;
  cart: Cart | undefined;
}

interface CompleteCustomer
  extends Omit<
    Customer,
    "acceptsMarketing" | "addresses" | "createdAt" | "displayName" | "id" | "orders" | "updatedAt" | "tags"
  > {
  token?: string;
  acceptsMarketing?: boolean;
  addresses?: MailingAddressConnection;
  createdAt?: DateTime;
  displayName?: string;
  id?: string;
  orders?: OrderConnection;
  updatedAt?: DateTime;
}

export interface Cart {
  attributes?: Attribute[];
  buyerIdentity?: CartBuyerIdentity;
  checkoutUrl?: string;
  createdAt?: DateTime;
  discountCodes?: DiscountCode[];
  estimatedCost?: CartEstimatedCost;
  id?: string;
  note?: string;
  updatedAt?: DateTime;
  lines?: {
    edges?: CartLine[];
  };
}

export interface CartBuyerIdentity {
  countryCode?: CountryCode;
  customer?: Customer;
  email?: string;
  phone?: string;
}
export interface CartLineMetafield {
  key?: string;
  namespace?: string;
}
export interface DiscountCode {
  applicable?: boolean;
  code?: string;
}

export interface CartEstimatedCost {
  subtotalAmount?: MoneyV2;
  totalAmount?: MoneyV2;
  totalDutyAmount?: MoneyV2;
  totalTaxAmount?: MoneyV2;
}

export interface CartLine {
  node?: {
    attributes?: Attribute[];
    discountAllocations?: any;
    estimatedCost?: any;
    id: string;
    merchandise?: {
      availableForSale?: boolean;
      compareAtPriceV2?: MoneyV2;
      currentlyNotInStock?: boolean;
      id?: string;
      image?: Image;
      metafield?: CartLineMetafield;
      priceV2?: MoneyV2;
      product?: Product;
      quantityAvailable?: number;
      requiresShipping?: boolean;
      selectedOptions?: SelectedOption[];
      sku?: string;
      title?: string;
      unitPrice?: MoneyV2;
      unitPriceMeasurement?: UnitPriceMeasurement;
      weight?: number;
      weightUnit?: WeightUnit;
    };
    quantity?: number;
    sellingPlanAllocation?: any;
  };
}

export interface UserProps {
  email: string;
  name: string;
  firstName: string;
  lastName: string;
  profilePic: string | null | undefined;
  savedBlogs: string[];
  savedRecipes: string[];
  uid: string | undefined;
  dob?: {
    day?: string;
    month?: string;
    year?: string;
  };
}

export interface RecipeRatingProps {
  recipeId: string;
  uid: string;
  rating: number;
}

export type EmailBody = {
  to: Maybe<string | undefined>;
  from: Maybe<string | undefined>;
  subject: Maybe<string | undefined>;
  html: Maybe<string | undefined>;
};

export function isSanityCtaBlock(data: any): data is SanityCtaBlock {
  return data._type === "ctaBlock";
}

export function isSanityProductReferenceCta(data: any): data is SanityProductReferenceCta {
  return data._type === "productReferenceCta";
}

export function isSanityCategoryPanelPreview(data: any): data is SanityCategoryPanelPreview {
  return data._type === "categoryPanelPreview";
}

export function isSanityColumn(data: any): data is SanityColumn {
  return data._type === "column";
}

export function isSanityColumnContent(data: any): data is SanityColumnContent {
  return data._type === "columnContent";
}

export function isSanityLearnCategoryPreview(data: any): data is SanityLearnCategoryPreview {
  return data._type === "learnCategoryPreview";
}

export function isSanityBlogPreview(data: any): data is SanityBlogPreview {
  return data._type === "blogPreview";
}

export function isSanityHero(data: any): data is SanityHero {
  return data._type === "hero";
}

export function isSanityRecipePreview(data: any): data is SanityRecipePreview {
  return data._type === "recipePreview";
}

export function isSanityIngredient(data: any): data is SanityIngredient {
  return data._type === "ingredient";
}

export function isSanityRecipeIngredient(data: any): data is SanityRecipeIngredient {
  return data._type === "recipeIngredient";
}

export function isSanityIngredientAmount(data: any): data is SanityIngredientAmount {
  return data._type === "ingredientAmount";
}

export function isSanityRecipe(data: any): data is SanityRecipe {
  return data._type === "recipe";
}

export function isSanityRecipeRefWithQuantity(data: any): data is SanityRecipeRefWithQuantity {
  return data._type === "recipeRefWithQuantity";
}

export function isSanityRecipeStep(data: any): data is SanityRecipeStep {
  return data._type === "recipeStep";
}

export function isSanityProductPreview(data: any): data is SanityProductPreview {
  return data._type === "productPreview";
}

export function isSanityVideo(data: any): data is SanityVideo {
  return data._type === "video";
}

export function isSanityCtaBanner(data: any): data is SanityCtaBanner {
  return data._type === "ctaBanner";
}

export function isSanitySuggestBlogs(data: any): data is SanitySuggestedBlogs {
  return data._type === "suggestedBlogs";
}

export function isSanityProfilesBlock(data: any): data is SanityProfilesBlock {
  return data._type === "profilesBlock";
}

export function isSanityProduct(data: any): data is SanityProduct {
  return data._type === "product";
}

export function isSanityFeedYourLifePreview(data: any): data is SanityFeedYourLifePreview {
  return data._type === "feedYourLifePreview";
}

export function isSanityBlog(data: any): data is SanityBlog {
  return data._type === "blog";
}

export function isSanityFeedYourLife(data: any): data is SanityFeedYourLife {
  return data._type === "feedYourLife";
}

export function isSanityFeedYourLifeInner(data: any): data is SanityFeedYourLifeInner {
  return data._type === "feedYourLifeInner";
}

export function isSanityContentSlider(data: any): data is SanityContentSlider {
  return data._type === "contentSlider";
}
