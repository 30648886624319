import { IconButton } from "@components/global";
import { useStore } from "@state/store";
import { colors, colorsRGB, icons, TABLET_BREAKPOINT } from "@util/constants";
import { isBrowser } from "@util/helper";
import { Container } from "@util/standard";
import { navigate } from "gatsby";
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import styled from "styled-components";

interface Props {
  whiteIcons?: boolean;
  margin?: string;
  hideButtons?: boolean;
  setHideButtons?: Dispatch<SetStateAction<boolean>>;
  width?: string;
  openOnLoad?: boolean;
}

const Input = styled.input<{ isVisible?: boolean; iswhite?: boolean }>`
  background: transparent;
  border: none;
  color: ${colors.darkerGreen};

  ${({ iswhite }) => iswhite && `color: ${colors.white};`};

  font-size: 15px;

  :focus-visible {
    outline: none;
  }
  ::placeholder {
    color: ${colorsRGB.darkerGreen(0.4)};
    ${({ iswhite }) => iswhite && `color: ${colorsRGB.white(0.4)};`};
  }

  width: ${props => (props.isVisible ? "200px" : "0px")};
  opacity: ${props => (props.isVisible ? "1" : "0")};

  transition: width 0.2s;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
  }
`;

const Icon = styled.img`
  cursor: pointer;
  height: 16px;
  width: 16px;
  margin: auto;
`;

function HeaderSearch({ whiteIcons, margin, width, hideButtons, openOnLoad, setHideButtons }: Props) {
  const [inputVisible, setInputVisible] = useState(false);
  const { headerSearch } = useStore();
  const [inputValue, setInputValue] = useState(headerSearch);
  const inputRef = useRef(null);

  const handleClick = () => {
    if (!inputVisible) {
      if (setHideButtons) {
        setHideButtons(true);
      }

      if (inputRef && inputRef.current) {
        inputRef.current.focus();
      }

      setInputVisible(true);
    } else if (inputVisible) {
      useStore.setState({ headerSearch: inputValue });
      navigate("/search-results");
    }
  };

  const checkClickTarget = e => {
    if (!e.target.classList.contains("searchContainer")) {
      setInputVisible(false);
    }
    return;
  };

  const handleKeyUp = e => {
    if (inputVisible) {
      if (e.key === "Enter") {
        useStore.setState({ headerSearch: inputValue });
        navigate("/search-results");
      }
    }

    return;
  };

  useEffect(() => {
    if (openOnLoad) {
      setInputVisible(true);
    }
    if (inputVisible && isBrowser()) {
      window.addEventListener("click", checkClickTarget);
      window.addEventListener("keyup", handleKeyUp);
    } else if (setHideButtons) {
      setHideButtons(false);
    }

    return () => {
      window.removeEventListener("click", checkClickTarget);
      window.removeEventListener("keyup", handleKeyUp);
    };
  });

  return (
    <Container
      width={width}
      height="30px"
      margin={margin}
      borderBottom={
        inputVisible && whiteIcons
          ? `1px solid ${colorsRGB.white(1)}`
          : inputVisible && !whiteIcons
          ? `1px solid ${colorsRGB.darkerGreen(1)}`
          : ""
      }
      className="searchContainer"
    >
      <Input
        className="searchContainer"
        iswhite={whiteIcons}
        ref={inputRef}
        isVisible={inputVisible}
        placeholder="Search"
        defaultValue={headerSearch ? headerSearch : ""}
        onChange={e => setInputValue(e.target.value)}
      />
      <Container className="searchContainer">
        <Icon
          className="searchContainer"
          src={whiteIcons ? icons.searchWhite : icons.search}
          onClick={() => handleClick()}
          alt="Search Icon"
        />
      </Container>
    </Container>
  );
}

export default HeaderSearch;
