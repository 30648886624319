import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { BlocksContent, Button, IconButton } from "@components/global";
import { SanityFooter } from "@graphql-types";
import { FooterQuery } from "@api";
import { A, Container, P } from "@util/standard";
import { isSanityCategory, isSanityLink } from "@util/types";
import { useCheckScreenWidth } from "@util/hooks";
import { navigate } from "gatsby";
import { useStore } from "@state/store";
import { colorsRGB } from "@util/constants";
import { activeCampaignAddContact, processEmail } from "@util/helper";
import { toast } from "react-toastify";

const StyledInput = styled.input`
  background: transparent;
  border: none;
  width: 100%;
  border-bottom: 1px solid white;
  padding-bottom: 12px;
  color: white;

  ::placeholder {
    color: white;
  }
  :focus-visible {
    outline: none;
  }
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
  opacity: 1;
  &:hover {
    opacity: 0.6;
    transition: opacity 0.2s;
  }
  transition: opacity 0.2s;
`;

const Footer = () => {
  const footerData: SanityFooter = FooterQuery();
  const { isLaptopWidth } = useCheckScreenWidth();
  if (footerData == null) {
    return null;
  }
  const [emailInputValue, setEmailInputtValue] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);

  const { footerFormBlock, footerSocials, footerMenus, privacyLinks, mobileMenu } = footerData;

  const { searchFilters, setHasSearched } = useStore();

  const handleClick = (category: string) => {
    if (searchFilters.includes(category)) {
      useStore.setState({
        searchFilters: [category],
        currentSearch: "",
      });
      setHasSearched(true);
      navigate("/recipes");
    } else {
      useStore.setState({ searchFilters: [category], currentSearch: "" });
      setHasSearched(true);

      navigate("/recipes");
    }
  };

  const handleFormSubmit = e => {
    if (emailInputValue === "" || !emailInputValue.includes("@")) {
      toast.error("Please enter a correct email");
      return;
    }

    const formattedEmail = {
      from: "info@langbein.com",
      to: process.env.GATSBY_CONTACT_RECIPIENT_EMAIL,
      subject: `Langbein.com newsletter signup`,
      html: `<div>
     <p>Email: ${emailInputValue}</p>
      </div>
        `,
    };
    processEmail(formattedEmail, emailInputValue)
      .then(result => {
        setFormSubmitted(true);
      })
      .catch(error => {
        toast.error("Something went wrong");
      });
  };

  return (
    <Container backgroundColor="mossGreen" width="100%" flexDirection="column">
      <Container padding="100px 0 0 0" mobilePadding="80px 0 100px 0" width="100%">
        <Container minWidth="50%" tabletWidth="100%" flexDirection="column">
          {footerFormBlock && footerFormBlock.textBlock && (
            <Container width="45%" mobileWidth="90%" flexDirection="column" margin="0 0 0 19%" tabletMargin="0 4%">
              <BlocksContent whiteText blocks={footerFormBlock.textBlock._rawColumnContent} />
              {!formSubmitted && (
                <Container flexDirection="column" margin="30px 0 0 0">
                  <StyledInput onChange={e => setEmailInputtValue(e.target.value)} placeholder="Email address" />
                  <Container justifyContent="flex-end" margin="20px 0 0 0">
                    <Button text="Sign Up" theme="textWhite" padding="0" onClick={e => handleFormSubmit(e)} />
                  </Container>
                </Container>
              )}
              {formSubmitted && footerFormBlock.thankyouText && (
                <Container flexDirection="column" margin="0px 0 0 0">
                  <Container margin="0px 0 0 0">
                    <BlocksContent whiteText blocks={footerFormBlock.thankyouText._rawColumnContent} />
                  </Container>
                </Container>
              )}
            </Container>
          )}
          {footerSocials && footerSocials.socialLinks && (
            <Container margin="0 0 0 19%" tabletMargin="0 4%">
              {footerSocials.socialLinks.map(item => {
                if (item == null) {
                  return null;
                }

                return (
                  <Container key={item._key} margin="40px 20px 0 0">
                    {item.socialIcon && item.socialIcon.asset && (
                      <A href={item.linkTo} target="_blank">
                        <Icon src={item.socialIcon?.asset.url} />
                      </A>
                    )}
                  </Container>
                );
              })}
            </Container>
          )}
        </Container>
        {isLaptopWidth && (
          <Container width="50%" padding="0 0 0 10%">
            {footerMenus?.map(menu => {
              if (menu == null || menu.menuLinks == null) {
                return null;
              }

              return (
                <Container flexDirection="column" width="25%" key={menu._key}>
                  <A color="white" opacity={0.4} margin="0 0 10px 0">
                    {menu?.menuName?.toUpperCase()}
                  </A>
                  {menu.menuLinks.subLink &&
                    menu.menuLinks.subLink.map(link => {
                      if (link == null) {
                        return null;
                      }

                      if (isSanityLink(link) && link.url) {
                        return (
                          <A underlineOnHover color="white" key={link._key} href={link.url} margin="0 0 10px 0">
                            {link.linktext}
                          </A>
                        );
                      }
                      if (isSanityCategory(link)) {
                        return (
                          <A
                            color="white"
                            underlineOnHover
                            onClick={() => handleClick(link.name ?? "")}
                            key={link.id}
                            margin="0 0 10px 0"
                          >
                            {link.name}
                          </A>
                        );
                      }
                      return null;
                    })}
                </Container>
              );
            })}
          </Container>
        )}
      </Container>
      <Container tabletMargin="0 0 60px 4%">
        {mobileMenu && !isLaptopWidth && (
          <Container flexDirection="column">
            {mobileMenu.map(link => {
              return (
                <A color="white" margin="6px 50px 6px 0" href={link?.url as string} key={link?._key}>
                  {link?.linktext}
                </A>
              );
            })}
          </Container>
        )}
        {privacyLinks && (
          <Container width="100%" flexDirection={!isLaptopWidth ? "column" : "initial"}>
            {privacyLinks.map(link => {
              return (
                <A
                  href={link?.url}
                  color="white"
                  margin={isLaptopWidth ? "20px 0px 20px 20px" : "6px 0"}
                  key={link?._key}
                >
                  {link?.linktext}
                </A>
              );
            })}
          </Container>
        )}
      </Container>
    </Container>
  );
};

export default Footer;
