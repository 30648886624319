import { useStaticQuery, graphql } from "gatsby";

import { Query } from "@graphql-types";

const FooterQuery = () => {
  const { allSanityFooter }: Query = useStaticQuery(graphql`
    {
      allSanityFooter {
        nodes {
          footerFormBlock {
            textBlock {
              _rawColumnContent
            }
            thankyouText {
              _rawColumnContent
            }
          }
          footerMenus {
            menuName
            _key
            menuLinks {
              _key
              _type
              subLink {
                ... on SanitySpecialDiets {
                  id
                  _key
                  _type
                  name
                }
                ... on SanityRecipeCategories {
                  id
                  _key
                  _type
                  name
                }
                ... on SanityOccasions {
                  id
                  _key
                  _type
                  name
                }
                ... on SanityLink {
                  ...sanityLink
                }
                ... on SanityLearnCategories {
                  id
                  _key
                  _type
                  name
                }
                ... on SanityFlavours {
                  id
                  _key
                  _type
                  name
                }
                ... on SanityLearnSubCategories {
                  id
                  _key
                  _type
                  name
                }
              }
            }
          }
          mobileMenu {
            ...sanityLink
          }
          footerSocials {
            _key
            socialLinks {
              linkTo
              _key
              socialIcon {
                altText
                asset {
                  url
                  gatsbyImageData
                }
              }
            }
          }
          privacyLinks {
            ...sanityLink
          }
        }
      }
    }
  `);

  return allSanityFooter.nodes[0];
};

export default FooterQuery;
