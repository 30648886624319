import React from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";

import { SanityContentSlider } from "@graphql-types";
import { Container } from "@util/standard";
import BlocksContent from "./blocksContent";
import { colors, colorsRGB, fontSizes, fontWeights, MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "@util/constants";

SwiperCore.use([Autoplay, Pagination, Navigation]);

const Wrapper = styled(Container)`
  background-color: ${colors.mossGreen};
`;

const StyledSwiper = styled(Swiper)`
  .swiper-button-next,
  .swiper-button-prev {
    color: ${colors.white};
    width: calc(var(--swiper-navigation-size) / 2 * 27);
    @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
      width: calc(var(--swiper-navigation-size) / 10 * 27);
    }
    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      width: calc(var(--swiper-navigation-size) / 20 * 27);
    }
  }
  &:root {
    --swiper-navigation-size: 10px;
  }
  .swiper-pagination-bullet-active {
    background-color: ${colors.white};
  }
  .swiper-pagination-bullet {
    background-color: ${colorsRGB.white(1)};
  }
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 70px;
    left: 0;
  }
`;

const BlocksContentContainer = styled(Container)`
  padding: 120px 0;
  width: 50%;
  margin: auto;
  justify-content: center;
  h1,
  h2,
  h3,
  h4 {
    font-weight: ${fontWeights.normal};
    text-align: center;
  }
  p {
    display: flex;
    justify-content: center;
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 70%;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    h1,
    h2,
    h3,
    h4 {
      font-size: ${fontSizes.h4.default}px;
      line-height: 26.4px;
    }
  }
`;

interface Props {
  data: SanityContentSlider;
}

const ContentSlider = ({ data }: Props) => {
  if (data == null) return null;
  return (
    <Wrapper>
      <StyledSwiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        autoHeight={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        className="mySwiper"
        style={{ "--swiper-navigation-size": "20px" }}
      >
        {data.sliderContent?.map(content => {
          if (content == null) return null;
          return (
            <SwiperSlide>
              <BlocksContentContainer>
                <BlocksContent blocks={content._rawColumnContent} whiteText />
              </BlocksContentContainer>
            </SwiperSlide>
          );
        })}
      </StyledSwiper>
    </Wrapper>
  );
};

export default ContentSlider;
