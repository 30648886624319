import { Link } from "gatsby";
import React from "react";

import { Image } from "@components/global";
import { animationTypes, colorFilter } from "@util/constants";
import AnimationContainer from "@components/global/animationContainer";
import styled from "styled-components";
import { SanityHeader } from "@graphql-types";

interface Props {
  disableScroll?: boolean;
  isGreenLogo?: boolean;
  mobileMenuOpen?: boolean;
  headerData: SanityHeader;
  userScrolled?: boolean;
}

const ImageWrapper = styled.div<{ userScrolled?: boolean }>`
  position: fixed;
  left: 50%;
  top: ${({ userScrolled }) => (userScrolled ? "20px;" : "15px;")};
  ${({ userScrolled }) => (userScrolled ? `filter: ${colorFilter.darkerGreen};` : `filter: none;`)}
  transform: translateX(-50%);
  transition: all 0.2s;
`;

const LogoButton = (props: Props) => {
  const { disableScroll, isGreenLogo, mobileMenuOpen, headerData, userScrolled } = props;

  return (
    <Link to="/" hasChildren>
      <ImageWrapper userScrolled={isGreenLogo}>
        {disableScroll && headerData.firstHeaderLogo?.asset?.url && (
          <AnimationContainer disableVisibilitySensor animationType={animationTypes.fadeIn} delay={200}>
            <Image image={headerData.firstHeaderLogo?.asset?.gatsbyImageData} altText="Langbein Logo" />
          </AnimationContainer>
        )}
        {userScrolled && !mobileMenuOpen && (
          <AnimationContainer disableVisibilitySensor animationType={animationTypes.fadeIn} delay={200}>
            <Image image={headerData.secondHeaderLogo?.asset?.gatsbyImageData} altText="Langbein Logo" />
          </AnimationContainer>
        )}
      </ImageWrapper>
    </Link>
  );
};

export default LogoButton;
