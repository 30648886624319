import React, { ReactNode } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { Spring, animated } from "react-spring";

interface Props {
  children: ReactNode;
  animationType: object;
  loop?: boolean;
  delay?: number;
  disablePartialVisibility?: boolean;
  disableVisibilitySensor?: boolean;
  config?: object;
  style?: object;
}

function AnimationContainer(props: Props) {
  const { children, animationType, loop, delay, disablePartialVisibility, disableVisibilitySensor, config } = props;

  const styles = { width: "100%" };

  return (
    <VisibilitySensor partialVisibility={!disablePartialVisibility}>
      {({ isVisible }) => (
        <Spring
          config={config}
          to={isVisible || disableVisibilitySensor ? animationType.to : null}
          from={animationType.from ? animationType.from : null}
          loop={loop}
          delay={delay ? delay : 0}
        >
          {styles => <animated.div style={{ width: "100%", ...styles }}>{children}</animated.div>}
        </Spring>
      )}
    </VisibilitySensor>
  );
}

export default AnimationContainer;
