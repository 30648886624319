import { useRef, useEffect, useState } from "react";

import { defaultScreenWidth } from "@state/store";

import { ScreenWidth } from "@util/types";
import { isBrowser } from "./helper";
import { MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "./constants";

export function usePrevious<T>(value: T) {
  const ref = useRef<T>(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

// COMPONENTDIDMOUNT

export function useHasMounted() {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }
  }, []);

  if (!mounted) {
    return false;
  }

  return mounted;
}

// ADD EVENT LISTENER

export function useEventListener(eventName: string, handler: (args: any) => void) {
  if (!isBrowser()) {
    return;
  }
  const savedHandler = useRef<typeof handler>();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = window && window.addEventListener;
    if (!isSupported) return;

    //@ts-ignore
    const eventListener = (event: any) => savedHandler.current(event);
    window.addEventListener(eventName, eventListener);

    return () => {
      window.removeEventListener(eventName, eventListener);
    };
  }, [eventName, window]);
}

// SCREENWIDTH CHECK

export function useCheckScreenWidth(): ScreenWidth {
  if (!isBrowser()) {
    return defaultScreenWidth;
  }
  const [screenWidth, setScreenWidth] = useState(defaultScreenWidth);
  const hasMounted = useHasMounted();

  const checkScreenWidth = () => {
    if (window.innerWidth <= MOBILE_BREAKPOINT) {
      setScreenWidth({
        ...defaultScreenWidth,
        isMobileWidth: true,
      });
      return;
    }
    if (window.innerWidth <= TABLET_BREAKPOINT) {
      setScreenWidth({
        ...defaultScreenWidth,
        isTabletWidth: true,
      });
      return;
    }
    if (window.innerWidth > TABLET_BREAKPOINT) {
      setScreenWidth({
        ...defaultScreenWidth,
        isLaptopWidth: true,
      });
      return;
    }
  };

  useEventListener("resize", checkScreenWidth);
  useEffect(() => {
    checkScreenWidth();
  }, []);

  useEffect(() => {
    if (hasMounted) {
      checkScreenWidth();
    }
  }, [hasMounted]);

  return screenWidth;
}

// console logs the state when it gets updated
export function useUpdateLogger(value: any) {
  useEffect(() => {
    console.log(value);
  }, [value]);
}

// counts the number of re-renders
export function useRenderCount() {
  const count = useRef(1);
  useEffect(() => {
    count.current++;
  }, []);
  return count.current;
}
