import { FirebaseProps, firestore } from "@lib/firebase";
import { useStore } from "@state/store";
import { toast } from "react-toastify";
import algoliasearch from "algoliasearch/lite";
import { EmailBody, RecipeRatingProps } from "./types";
import { update } from "@react-spring/core";

interface SaveRecipeProps {
  id?: string | null;
  savedRecipes: string[];
  userUId?: string;
  isUserSignedIn: boolean | undefined;
}

interface SaveBlogProps {
  id?: string | null;
  savedBlogs: string[];
  userUId?: string;
  isUserSignedIn: boolean | undefined;
}
interface UserDataProps {
  firstName: string;
  lastName: string;
  displayName?: string;
  profilePic?: string;
  email: string;
  uid: string;
  newsLetter?: boolean;
  stayLoggedIn?: boolean;
  savedBlogs?: string[];
  savedRecipes?: string[];
  dob?: {
    day?: string;
    month?: string;
    year?: string;
  };
}

export const isBrowser = () => typeof window !== "undefined";

export const setSavedRecipes = (savedIds: string[]) => {
  useStore.setState({ savedRecipes: savedIds });
};

export const setSavedBlogs = (savedIds: string[]) => {
  useStore.setState({ savedBlogs: savedIds });
};

// SET USER DETAILS IN STORE

export const setUser = (uid: string) => {
  firestore
    .collection("users")
    .doc(uid)
    .get()
    .then(snapshot => {
      const userData = snapshot.data();

      if (userData == null) {
        console.log("an error occurred when trying to get user data");
        return null;
      }

      return useStore.setState({
        user: userData ?? {},
        savedRecipes: userData.savedRecipes ?? [],
        savedBlogs: userData.savedBlogs ?? [],
        isLoggedIn: true,
        authMenuOpen: false,
      });
    })
    .catch(err => console.log(err));
};

// SAVE RECIPES

export const handleSaveRecipe = (props: SaveRecipeProps) => {
  if (!props.isUserSignedIn) {
    useStore.setState({ authMenuOpen: true });
  }

  const { id, savedRecipes, userUId } = props;
  if (!id) {
    return;
  }
  const uniqueIds = savedRecipes && savedRecipes.length > 0 ? [...new Set([...savedRecipes, id])] : [id];
  const savedIds = savedRecipes && savedRecipes.includes(id) ? savedRecipes.filter(recipe => recipe !== id) : uniqueIds;

  setSavedRecipes(savedIds);
  firestore.collection("users").doc(userUId).update({
    savedRecipes: savedIds,
  });
};

// SAVE BLOGS

export const handleSaveBlog = (props: SaveBlogProps) => {
  if (!props.isUserSignedIn) {
    useStore.setState({ authMenuOpen: true });
  }

  const { id, savedBlogs, userUId } = props;
  if (!id) {
    return;
  }
  const uniqueIds = savedBlogs && savedBlogs.length > 0 ? [...new Set([...savedBlogs, id])] : [id];
  const savedIds = savedBlogs && savedBlogs.includes(id) ? savedBlogs.filter(blog => blog !== id) : uniqueIds;

  setSavedBlogs(savedIds);
  firestore.collection("users").doc(userUId).update({
    savedBlogs: savedIds,
  });
};

// SET USER FIREBASE DETAILS

export const setUserDetails = (userData: UserDataProps) => {
  const docRef = firestore.collection("users");

  if (userData == null) {
    return null;
  }

  const googleData = {
    firstName: userData.firstName,
    lastName: userData.lastName,
    name: userData.name,
    email: userData.email,
    profilePic: userData.profilePic ?? "",
  };

  const myDetailsData = {
    firstName: userData.firstName,
    lastName: userData.lastName,
    name: userData.name,
    email: userData.email,
    profilePic: userData.profilePic ?? "",
    dob: userData.dob && userData.dob,
  };

  docRef
    .doc(userData.uid)
    .update(userData.dob ? myDetailsData : googleData)
    .then(results => {
      return results;
    })
    .catch(error => {
      console.log(error);
    });
  return;
};

// ACTIVECAMPAIGN API CALL

export const activeCampaignAddContact = async (e, data) => {
  const postData = {
    contact: data,
  };
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Content-Type",
      "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
    },
    body: JSON.stringify(postData),
  };

  const APIENDPOINT = `${process.env.GATSBY_BASE_URL}/.netlify/functions/addContact`;

  fetch(APIENDPOINT, options)
    .then(function (response) {
      toast.success("Succesfully Submitted");
      return response;
    })
    .catch(function (error) {
      toast.error(error);
      return error;
    });
};

//Alogolia Search client
const algoliaClient = algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID, process.env.GATSBY_ALGOLIA_SEARCH_ONLY_KEY);

export const searchClient = {
  search(requests: any) {
    const shouldSearch = requests.some(({ params: { query } }) => query !== "");
    const facetFilter = requests.some(params => params.params["facetFilters"] !== undefined);

    if (shouldSearch || facetFilter) {
      return algoliaClient.search(requests);
    }

    return Promise.resolve({
      results: [{ hits: [] }],
    });
  },
  searchForFacetValues: algoliaClient.searchForFacetValues,
};

export function formatDateDropdown() {
  const days = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17.18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
  ];

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const currentYear = new Date().getFullYear();

  let years = [];
  for (let i = 1950; i <= currentYear; i++) {
    years.push(i.toString());
  }

  return { days, months, years };
}

// RECIPE RATINGS

export const addRecipeRating = (uid: string | undefined, recipeId: string, rating: number) => {
  if (uid == null || recipeId == null || rating == null) return null;

  const docRef = firestore.collection("recipeRatings");

  docRef
    .doc(uid + recipeId)
    .set(
      {
        recipeId: recipeId,
        uid: uid,
        rating: rating,
      },
      { merge: true },
    )
    .then(results => {
      return results;
    })
    .catch(error => {
      console.log(error);
    });
  return;
};

export function fetchRecipeRatings(recipeId: string) {
  const ratingData = firestore
    .collection("recipeRatings")
    .get()
    .then(snapshot => {
      const filteredRatings = snapshot.docs.filter(doc => {
        return doc.data().recipeId === recipeId;
      });

      return filteredRatings.map(doc => {
        return doc.data();
      });
    })
    .catch(error => console.log(error));

  return ratingData;
}

export function getMedianRecipeRating(ratings: RecipeRatingProps[]) {
  const length = ratings.length;
  let sum = 0;
  ratings.map(rating => {
    if (rating == null) return;
    sum = sum + rating.rating;
    return;
  });

  const median = sum / length;
  return Number(median.toFixed(1));
}

export const processEmail = async (data: EmailBody, email: string) => {
  if (data.to == null || data.from == null) {
    toast.error("Recipient or Sender email not defined");
    return;
  }

  const zap = await fetch(`https://hooks.zapier.com/hooks/catch/16031294/31x1vel/`, {
    method: "POST",
    body: JSON.stringify({ email: email }),
  });

  console.log(zap);
  return;

  const requestBody = JSON.stringify(data);

  const request = await fetch(`/.netlify/functions/sendEmail`, {
    method: "POST",
    body: requestBody,
  });

  try {
    const response = await request.json();

    if (response) {
      return response;
    }

    if (response.error) {
      return response.error;
    }
  } catch (err) {
    throw err;
  }
};

export const getAllContacts = async () => {
  const request = await fetch(`/.netlify/functions/getAllActiveCampaignContacts`, {
    method: "GET",
  });

  try {
    const response = await request.json();

    if (response) {
      return response;
    }

    if (response.error) {
      return response.error;
    }
  } catch (err) {
    throw err;
  }
};
