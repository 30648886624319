import React, { CSSProperties, lazy, Suspense, useEffect, useState } from "react";
import styled from "styled-components";

import { Button, IconButton } from "@components/global";
import { useStore } from "@state/store";
import { colors, colorsRGB, fontWeights, icons } from "@util/constants";
import { Container, P } from "@util/standard";
import { isBrowser } from "@util/helper";
import { useCheckScreenWidth, useHasMounted } from "@util/hooks";
import { CheckoutLineItemInput } from "@util/types";
import Checkout from "@components/global/checkout";
import HeaderSearch from "./headerSearch";

interface Props {
  userScrolled: boolean | undefined;
  justifyContent?: CSSProperties["justifyContent"];
}

const CartItemsCount = styled(Container)<{ userScrolled: boolean }>`
  background-color: ${colors.white};
  width: 24px;
  height: 24px;
  text-align: center;
  color: ${colors.darkerGreen};
  border-radius: 12px;
  font-size: 13px;
  margin: auto;
  text-align: center;
  ${({ userScrolled }) =>
    userScrolled &&
    `
  background-color: ${colors.darkerGreen};
  color: ${colors.white};

`}
`;

const Auth = lazy(() => import("@shared/auth"));

function HeaderButtons(props: Props) {
  const { userScrolled, justifyContent } = props;
  const { isLoggedIn, user, checkout } = useStore();
  const { isLaptopWidth } = useCheckScreenWidth();
  const [cartItemsAmount, setCartItemsAmount] = useState(0);
  const [hideButtons, setHideButtons] = useState(false);

  const getTotalCartItems = () => {
    if (checkout == null) {
      return null;
    }

    let count = 0;
    checkout.lineItems.map((item: CheckoutLineItemInput) => {
      count = count + item.quantity;
    });
    return count;
  };

  useEffect(() => {
    if (checkout != null) {
      setCartItemsAmount(getTotalCartItems());
    }
  }, []);

  return (
    <>
      {useHasMounted() && (
        <Container width="100%" justifyContent={justifyContent} margin="0" tabletMargin="0 10px 0 0">
          {isLaptopWidth && !isLoggedIn && !hideButtons && (
            <Container height={userScrolled ? "38px" : "42px"} margin="auto 0">
              <Button
                theme={userScrolled ? "bordered" : "borderedWhite"}
                text="Create Account"
                borderOverride={
                  userScrolled ? `1.5px solid ${colorsRGB.darkerGreen(0.4)}` : `1.5px solid ${colorsRGB.white(0.4)}`
                }
                borderThickness="1px"
                padding="0px 25px"
                margin="0px 35px 0 0"
                onClick={() => useStore.setState({ authMenuOpen: true, createAccountFormOpen: true })}
                borderRadius="2px"
              />
            </Container>
          )}
          {!hideButtons && isLaptopWidth && user && isLoggedIn && (
            <Button
              margin="0 20px 0 0"
              padding="10px 25px"
              linkTo="/my-saved"
              borderOverride={
                userScrolled ? `1.5px solid ${colorsRGB.darkerGreen(0.4)}` : `1.5px solid ${colorsRGB.white(0.4)}`
              }
              theme={userScrolled ? "bordered" : "borderedWhite"}
              text="My Saved"
              icon={userScrolled ? icons.unSavedDark : icons.unSaved}
              fontWeight={fontWeights.normal}
              borderRadius="2px"
            />
          )}
          {isLoggedIn && user && !hideButtons ? (
            <Container margin="0 20px 0 0" tabletMargin="0 5% 0 0">
              <IconButton
                textColor={props.userScrolled ? "darkerGreen" : "white"}
                icon={userScrolled ? icons.login : icons.loginWhite}
                text={isLaptopWidth ? user.firstName : null}
                href="/account"
                alt="User Icon"
              />
            </Container>
          ) : !hideButtons ? (
            <IconButton
              textColor={props.userScrolled ? "darkerGreen" : "white"}
              icon={userScrolled ? icons.login : icons.loginWhite}
              hoverEffect
              alt="Login Icon"
              margin="0 30px 0 0"
              text={isLaptopWidth && !isLoggedIn ? "Login" : null}
              onClick={() => useStore.setState({ authMenuOpen: true })}
            />
          ) : null}
          {!hideButtons && (
            <Container onClick={() => useStore.setState({ checkoutOpen: true })} cursor="pointer">
              <IconButton margin="0 5px 0 0" icon={userScrolled ? icons.cart : icons.cartWhite} alt="Cart Icon" />
              {cartItemsAmount > 0 && (
                <CartItemsCount userScrolled={userScrolled}>
                  <P margin="auto" color={userScrolled ? "white" : "darkerGreen"}>
                    {cartItemsAmount}
                  </P>
                </CartItemsCount>
              )}
            </Container>
          )}
          {isLaptopWidth && (
            <HeaderSearch setHideButtons={setHideButtons} margin="auto 0 auto 30px" whiteIcons={!userScrolled} />
          )}
        </Container>
      )}
      <Checkout />
      {isBrowser() && (
        <Suspense fallback={<div />}>
          <Auth />
        </Suspense>
      )}
    </>
  );
}

export default HeaderButtons;
