import { SanityProduct } from "@graphql-types";
import { useStore } from "@state/store";
import { animationTypes, MOBILE_BREAKPOINT, TABLET_BREAKPOINT, TWENTY_INCH_SCREEN } from "@util/constants";
import { useCheckScreenWidth } from "@util/hooks";
import { addItemToCart, getProduct } from "@util/shopifyHelper";
import { Container, P, A } from "@util/standard";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import AnimationContainer from "./animationContainer";
import Button from "./button";
import { toast } from "react-toastify";
import CartSuccessToast from "./cartSuccessToast";

interface Props {
  data: SanityProduct;
  margin?: string;
}

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const StyledImg = styled.img`
  object-fit: cover !important;
  min-height: 400px;

  @media only screen and (min-width: ${TWENTY_INCH_SCREEN}px) {
    height: 550px;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    min-height: 300px;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    min-height: 230px;
  }
`;

const Price = styled(P)<{ sale?: boolean }>`
  ${({ sale }) =>
    sale &&
    `text-decoration: line-through;
    opacity: 0.4;`};
  margin-right: 20px;
`;

function ProductCard({ data, margin }: Props) {
  if (data == null) {
    return null;
  }

  const [cartButtonVisible, setCartButtonVisible] = useState(false);
  const { isLaptopWidth } = useCheckScreenWidth();
  const { checkout } = useStore();

  useEffect(() => {
    if (!isLaptopWidth) {
      setCartButtonVisible(false);
    }
  });

  const handleClick = async () => {
    if (checkout) {
      getProduct(data)
        .then(product => {
          const productId = product[0].variants[0].id;
          addItemToCart(checkout.id, productId as string, 1);
          toast.success(CartSuccessToast);
        })
        .catch(error => {
          toast.error(`Something went wrong when adding Item to Cart`);
        });
    }
  };

  return (
    <Container
      flexDirection="column"
      cursor="pointer"
      width="100%"
      height="100%"
      margin={margin}
      onMouseEnter={() => setCartButtonVisible(true)}
      onMouseLeave={() => setCartButtonVisible(false)}
    >
      <Container>
        {data.productImages && data.productImages[0] && (
          <A href={`/products/${data.slug?.current}`}>
            <ImageWrapper>
              <StyledImg style={{ width: "100%" }} src={data.productImages[0]?.asset?.url as string} />
            </ImageWrapper>
          </A>
        )}
      </Container>
      <Container flexDirection="column" height="57px">
        {!cartButtonVisible && (
          <P margin="20px 0 15px 0" color="black">
            {data.productName?.toUpperCase()}
          </P>
        )}
        {cartButtonVisible && (
          <AnimationContainer animationType={animationTypes.fadeIn}>
            <Button width="100%" onClick={() => handleClick()} theme="block" text="Add to cart" margin="16px 0" />
          </AnimationContainer>
        )}
        <Container>
          <Price noMargin sale={data.onSale as boolean}>
            ${data.price}
          </Price>
          {data.onSale && <P noMargin>${data.salePrice}</P>}
        </Container>
      </Container>
    </Container>
  );
}

export default ProductCard;
