import { useStaticQuery, graphql } from "gatsby";

import { Query } from "@graphql-types";

const HeaderQuery = () => {
  const { allSanityHeader }: Query = useStaticQuery(graphql`
    {
      allSanityHeader {
        nodes {
          firstHeaderLogo {
            asset {
              url
              gatsbyImageData(placeholder: NONE)
            }
            altText
          }
          secondHeaderLogo {
            asset {
              gatsbyImageData(placeholder: NONE)
            }
            altText
          }
          headerMenu {
            ... on SanityLink {
              _key
              _type
              linktext
              url
              internallink
            }
          }
        }
      }
    }
  `);

  return allSanityHeader.nodes[0];
};

export default HeaderQuery;
