import React from "react";
import styled from "styled-components";
import { IGatsbyImageData } from "gatsby-plugin-image";

import { assets, BUTTON_HEIGHT } from "@util/constants";
import { Asset, Color } from "@util/types";
import { A, Container, P } from "@util/standard";

const TextButton = styled(Container)<{
  color?: Color;
  hoverEffect?: boolean;
  margin?: string;
}>`
  margin: 0 15px 0 15px;
  ${({ margin }) => margin && `margin: ${margin};`};
  align-items: center;
  background-color: transparent;
  height: ${BUTTON_HEIGHT}px;

  cursor: pointer;
  user-select: none;

  ${({ hoverEffect }) =>
    hoverEffect &&
    `
    &:hover {
      p {
        text-decoration: underline;
      }
    }
 `}
`;

const Icon = styled.img<{ isWhite?: boolean; iconDimensions?: string; iconWidth?: string; iconHeight?: string }>`
  /* ${({ isWhite }) => (isWhite ? "filter: brightness(1000%);" : "filter: brightness(100%);")}; */
  ${({ iconDimensions }) => iconDimensions && `width: ${iconDimensions}px; height: ${iconDimensions};`};
  ${({ iconHeight }) => iconHeight && `height: ${iconHeight};`};
  ${({ iconWidth }) => iconWidth && `width: ${iconWidth};`};
`;

interface Props {
  disabled?: boolean;
  className?: string;
  color?: Color;
  icon?: Asset | string | undefined | null;
  externalIcon?: IGatsbyImageData | null | undefined;
  onClick?: (arg?: any) => void;
  isPlain?: boolean;
  hideOnDesktop?: boolean;
  leftRightMargin?: string;
  text?: string | null;
  textColor?: Color;
  hoverEffect?: boolean;
  alt?: string;
  href?: string | null | undefined;
  whiteIcon?: boolean | null | undefined;
  newWindow?: boolean;
  iconDimensions?: string;
  margin?: string;
  iconWidth?: string;
  iconHeight?: string;
  textUnderlined?: boolean;
}

const IconButton = (props: Props) => {
  if (props.href && props.icon) {
    return (
      <A href={props.href} target={props.newWindow ? "_blank" : ""}>
        <Container margin="10px 15px" alignItems="center">
          <Icon
            // isWhite={!props.whiteIcon as boolean}
            src={props.icon}
            alt={props.alt ?? "icon-button"}
            width="22px"
            height="22px"
          />
          {props.text && (
            <P underlined={props.textUnderlined} noMargin color={props.textColor} style={{ paddingLeft: 8 }}>
              {props.text}
            </P>
          )}
        </Container>
      </A>
    );
  }

  return (
    <TextButton margin={props.margin} hoverEffect={props.hoverEffect} onClick={props.onClick} color={props.color}>
      {props.icon && (
        <Icon
          iconDimensions={props.iconDimensions}
          // isWhite={!props.whiteIcon as boolean}
          src={props.icon}
          alt={props.alt}
          width={props.iconWidth ? props.iconWidth : "24px"}
          height={props.iconHeight ? props.iconHeight : "22px"}
        />
      )}
      {props.text && (
        <P underlined={props.textUnderlined} noMargin color={props.textColor} style={{ paddingLeft: 8 }}>
          {props.text}
        </P>
      )}
    </TextButton>
  );
};

export default IconButton;
