import React from "react";
import styled from "styled-components";

import { animationTypes, colors, colorsRGB, fontFamilies, fontWeights } from "@util/constants";
import { Container, A } from "@util/standard";
import { isSanityLink } from "@util/types";
import HeaderQuery from "@api/headerQuery";
import AnimationContainer from "@components/global/animationContainer";
import { SanityHeader } from "@graphql-types";
import HeaderSearch from "./headerSearch";
import { Button } from "@components/global";
import { useStore } from "@state/store";

interface Props {
  menuOpen: boolean;
}

const MobileLink = styled(A)<{ isLast?: boolean }>`
  font-size: 22px;
  font-family: ${fontFamilies.feijoa};
  font-weight: ${fontWeights.normal};
  color: ${colors.white};
  width: 100%;
  padding: 25px 0 25px 45px;
  ${({ isLast }) => (isLast ? `border: none;` : `border-bottom: 1px solid ${colorsRGB.white(0.2)};`)};
`;

function MobileMenu({ menuOpen }: Props) {
  const headerData: SanityHeader = HeaderQuery();

  if (headerData == null || headerData.headerMenu == null) {
    return null;
  }

  const menuLength = headerData.headerMenu.length;
  const { isLoggedIn } = useStore();
  return (
    <Container
      width="100%"
      backgroundColor="mossGreen"
      position="fixed"
      height="100%"
      top="0"
      left={menuOpen ? "0px" : "-100%"}
      transitionAll={0.2}
      zIndex={4}
    >
      <Container margin="176px 0px 0px 0px" flexDirection="column" width="100%" overflow="scroll">
        {menuOpen && (
          <>
            {headerData.headerMenu.map((menuItem, index) => {
              const isLast = index === menuLength - 1;

              if (isSanityLink(menuItem)) {
                if (menuItem == null || menuItem.linktext == null || menuItem.linktext == null) {
                  return null;
                }

                return (
                  <AnimationContainer
                    animationType={animationTypes.fadeInFromBottom}
                    delay={index * 100 + 200}
                    key={menuItem._key}
                    disableVisibilitySensor
                  >
                    <Container key={menuItem._key}>
                      <MobileLink isLast={isLast} href={menuItem.url as string}>
                        {menuItem.linktext}
                      </MobileLink>
                    </Container>
                  </AnimationContainer>
                );
              }
              return null;
            })}
            <Container margin="14% 45px 80px 45px" flexDirection="column-reverse">
              <AnimationContainer
                animationType={animationTypes.fadeInFromBottom}
                delay={headerData.headerMenu.length * 100 + 200}
                disableVisibilitySensor
              >
                {isLoggedIn && (
                  <Button linkTo="/my-saved" width="100%" margin="0 0 20px 0" text="My Saved" theme="blockWhite" />
                )}

                <HeaderSearch width="100%" whiteIcons openOnLoad />
              </AnimationContainer>
            </Container>
          </>
        )}
      </Container>
    </Container>
  );
}

export default MobileMenu;
