import { useStaticQuery, graphql } from "gatsby";

import { Query } from "@graphql-types";

const recipesQuery = () => {
  const { allSanityRecipe }: Query = useStaticQuery(graphql`
    query recipesQuery {
      allSanityRecipe {
        nodes {
          ...sanityRecipe
        }
      }
    }
  `);

  return allSanityRecipe.nodes;
};

export default recipesQuery;
