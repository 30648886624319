import { useStore } from "@state/store";
import { colors, colorsRGB, icons } from "@util/constants";
import { useCheckScreenWidth } from "@util/hooks";
import { removeItemFromCart, updateLineItemQuanity } from "@util/shopifyHelper";
import { A, Container, H3, P, H4 } from "@util/standard";
import { LineItemAttributes } from "@util/types";

import React, { useEffect } from "react";
import { CheckoutLineItem, CheckoutLineItemConnection } from "shopify-storefront-api-typings";
import styled from "styled-components";
import { Button } from ".";

const Close = styled.img`
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
`;

const StyledButton = styled.button<{ left?: boolean }>`
  min-width: 20px;
  max-height: 35px;
  background: ${colors.mossGreen};
  border: none;
  border-radius: 0px;

  ${({ left }) =>
    left
      ? `
      border-bottom-left-radius: 0px;
      border-top-left-radius: 0px;
  `
      : `
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
  `};

  color: ${colors.white};
  cursor: pointer;
`;
const QuantityInput = styled.input`
  max-width: 30px;
  max-height: 33px;
  padding: 0;
  text-align: center;
  ::placeholder {
    padding: 0;
  }

  border: none;
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
`;

const ProductImg = styled.img`
  width: 50px;
`;

function Checkout() {
  const { checkoutOpen, checkout } = useStore();
  const { isLaptopWidth } = useCheckScreenWidth();

  useEffect(() => {});
  return (
    <Container
      opacity={checkoutOpen ? 1 : 0}
      width={checkoutOpen ? "100vw" : "0"}
      height="100vh"
      position="absolute"
      left="0px"
      top="0px"
      backgroundColor="white"
      zIndex={checkoutOpen ? 999 : 0}
    >
      <Container
        width={isLaptopWidth ? "30%" : "100%"}
        height="100vh"
        backgroundColor="white"
        position="fixed"
        right={isLaptopWidth ? "0px" : undefined}
        left={isLaptopWidth ? undefined : "0px"}
        top={isLaptopWidth ? "0px" : undefined}
        translateX={checkoutOpen ? "0%" : "100%"}
        transitionAll={0.5}
        flexDirection="column"
      >
        <Container height="100%" width="80%" margin="60px auto" flexDirection="column">
          <H3 margin="50px 0 0 0" color="darkerGreen" isBold>
            Cart
          </H3>
          <P>Taxes and shipping calculated at checkout</P>
          {checkout && checkout.lineItems && (
            <Container height="90%" flexDirection="column">
              {checkout.lineItems.map((product: CheckoutLineItem) => {
                if (product == null) {
                  return null;
                }
                return (
                  <Container width="100%" flexDirection="column" key={product.id} margin="15px 0">
                    <Container>
                      {product.variant && product.variant.image && <ProductImg src={product.variant.image.src} />}
                      <P fontSize={18} width="50%" margin="auto">
                        {product.title}
                      </P>
                      <Container height="45px" width="50px" margin="auto">
                        <StyledButton
                          left
                          onClick={() => updateLineItemQuanity(checkout.id, product.id, product.quantity - 1)}
                        >
                          -
                        </StyledButton>
                        {product.quantity && (
                          <QuantityInput
                            placeholder={product.quantity}
                            onChange={e => updateLineItemQuanity(checkout.id, product.id, Number(e.target.value))}
                          />
                        )}
                        <StyledButton
                          onClick={() => updateLineItemQuanity(checkout.id, product.id, product.quantity + 1)}
                        >
                          +
                        </StyledButton>
                      </Container>
                    </Container>
                    <P width="100%" textAlign="right" noMargin>
                      <A underlined onClick={() => removeItemFromCart(checkout.id, product.id)}>
                        Delete
                      </A>
                    </P>
                  </Container>
                );
              })}
            </Container>
          )}
          <Container borderTop={`1px solid ${colorsRGB.black(0.5)}`} flexDirection="column">
            <H4 fontFamily="proximaNova">Total: ${checkout?.subtotalPrice?.amount}</H4>
            <A href={checkout?.webUrl} target="_blank">
              <Button width="100%" text="Proceed to Checkout" theme="block" />
            </A>
          </Container>
        </Container>

        <Close src={icons.closeDark} onClick={() => useStore.setState({ checkoutOpen: false })} />
      </Container>
      {checkoutOpen && <Container width="100%" height="100%" backgroundRGBColor={colorsRGB.black(0.8)}></Container>}
    </Container>
  );
}

export default Checkout;
