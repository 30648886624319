import { SanityProduct } from "@graphql-types";
import { useStore } from "@state/store";
import { toast } from "react-toastify";
import Client from "shopify-buy";

const client = Client.buildClient({
  storefrontAccessToken: "876ebcb44fa7f73ad116ce93de5b7b39",
  domain: "langbeins.myshopify.com",
});

export const createCheckout = async () => {
  const checkout = await client.checkout.create();

  useStore.setState({ checkout: checkout });
};

export const addItemToCart = async (checkoutId: string, variantId: string, quantity: number) => {
  if (variantId == null) {
    return null;
  }
  const lineItemToAdd = [
    {
      variantId: variantId,
      quantity: quantity ?? 1,
    },
  ];

  const currentCheckout = await client.checkout.addLineItems(checkoutId, lineItemToAdd);
  useStore.setState({ checkout: currentCheckout });
  return;
};

export const getAllProducts = async () => {
  const products = await client.product.fetchAll();
  return products;
};

export const getProduct = (lineItem: SanityProduct) => {
  return getAllProducts().then(products => {
    const filteredProduct = products.filter(product => {
      return product.variants[0].sku === lineItem.productCode;
    });
    return filteredProduct;
  });
};

export const removeItemFromCart = async (checkoutId: string, variantId: string) => {
  if (variantId == null) {
    return null;
  }
  const lineItemToRemove = [variantId];

  const currentCheckout = await client.checkout.removeLineItems(checkoutId, lineItemToRemove);
  useStore.setState({ checkout: currentCheckout });

  return;
};

export const updateLineItemQuanity = async (checkoutId: string, variantId: string, quantity: number) => {
  if (variantId == null) {
    return null;
  }
  const lineItemsToUpdate = [{ id: variantId, quantity: quantity ?? 1 }];

  const currentCheckout = await client.checkout.updateLineItems(checkoutId, lineItemsToUpdate);
  useStore.setState({ checkout: currentCheckout });

  return;
};
