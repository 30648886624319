import React from "react";
import styled from "styled-components";

import { P } from "@util/standard";
import { icons, MOBILE_BREAKPOINT } from "@util/constants";

interface Props {
  onChange?: (arg?: any) => void;
  text?: string;
  checked?: boolean;
  name?: string;
  padding?: string;
  mobilePadding?: string;
  isDark?: boolean;
}

const StyledLabel = styled.label<{
  padding?: string;
  mobilePadding?: string;
}>`
  ${({ mobilePadding }) =>
    mobilePadding &&
    `@media only screen and (max-width:${MOBILE_BREAKPOINT}px) {
  padding: ${mobilePadding};
}`}
  ${({ padding }) => padding && `padding: ${padding};`}
  display: flex;
  align-items: center;
`;

const CheckIcon = styled.img``;

const Checkbox = (props: Props) => {
  const { checked, isDark } = props;

  return (
    <StyledLabel padding={props.padding} mobilePadding={props.mobilePadding}>
      <CheckIcon
        src={
          isDark && checked
            ? icons.checkedDark
            : checked && !isDark
            ? icons.checked
            : isDark && !checked
            ? icons.uncheckedDark
            : icons.unchecked
        }
      />
      {/* <input name={props.name} type="checkbox" checked={props.checked} onChange={props.onChange} /> */}
      {props.text && <P style={{ margin: 0, paddingLeft: 5 }}>{` ${props.text}`}</P>}
    </StyledLabel>
  );
};

export default Checkbox;
