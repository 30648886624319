import { useStaticQuery, graphql } from "gatsby";

import { Query } from "@graphql-types";

const OurPicks = () => {
  const allSanityOurPicks: Query = useStaticQuery(graphql`
    {
      allSanityOurPicks {
        nodes {
          recipeRefs {
            ...sanityRecipe
          }
        }
      }
    }
  `);
  return allSanityOurPicks.allSanityOurPicks.nodes[0];
};

export default OurPicks;
