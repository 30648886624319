import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Container, A } from "@util/standard";
import { SanityHeader } from "@graphql-types";
import { isSanityLink } from "@util/types";
import { IconButton, Image, Link } from "@components/global";
import { animationTypes, colorFilter, colors, icons } from "@util/constants";
import AnimationContainer from "@components/global/animationContainer";
import HeaderButtons from "@components/header/headerButtons";

import { HeaderQuery } from "@api";
import MobileMenu from "@components/header/mobileMenu";
import { useCheckScreenWidth, useHasMounted } from "@util/hooks";
import LogoButton from "@components/global/logoButton";

interface Props {
  onWhiteBackground?: boolean;
  isHomePage?: boolean;
}

const Header = ({ onWhiteBackground, isHomePage }: Props) => {
  const headerData: SanityHeader = HeaderQuery();

  const [userScrolled, setUserScrolled] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isGreenLogo, setIsGreenLogo] = useState<boolean>();

  if (headerData == null || headerData.headerMenu == null) {
    return null;
  }

  const checkForScroll = () => {
    window.scrollY >= 10 ? setUserScrolled(true) : setUserScrolled(false);
  };

  const { isLaptopWidth } = useCheckScreenWidth();

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", checkForScroll);
    }
    return () => window.removeEventListener("scroll", checkForScroll);
  });

  const disableScroll = mobileMenuOpen || !userScrolled;

  useEffect(() => {
    (userScrolled && !mobileMenuOpen) || (onWhiteBackground && !mobileMenuOpen)
      ? setIsGreenLogo(true)
      : setIsGreenLogo(false);
  });

  return (
    <>
      {useHasMounted() && (
        <Container
          backgroundColor={!disableScroll ? "white" : mobileMenuOpen ? "mossGreen" : "transparent"}
          boxShadow={userScrolled && !mobileMenuOpen ? "0px 0px 15px rgba(0, 0, 0, 0.1);" : ""}
          position="fixed"
          top="0px"
          width="100%"
          zIndex={5}
        >
          <AnimationContainer
            animationType={isHomePage ? animationTypes.slideInFromTop("-50px", 0) : animationTypes.noAnimation}
            delay={500}
          >
            <Container>
              {!isLaptopWidth ? (
                <Container width="50%" margin={!disableScroll ? "12px 16px" : "30px 10px"}>
                  <Container>
                    <IconButton
                      whiteIcon={(userScrolled && !mobileMenuOpen) || (onWhiteBackground && !mobileMenuOpen)}
                      icon={mobileMenuOpen ? icons.close : isGreenLogo ? icons.hamburger : icons.hamburgerWhite}
                      onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                    />
                  </Container>
                </Container>
              ) : (
                <Container width="50%" margin={!disableScroll ? "20px 30px" : "30px"}>
                  {headerData.headerMenu.map(menuItem => {
                    if (isSanityLink(menuItem)) {
                      if (menuItem == null || menuItem.linktext == null || menuItem.linktext == null) {
                        return null;
                      }
                      return (
                        <Container margin="0px 5% 0px 0px" key={menuItem._key}>
                          <Link
                            linktext={menuItem.linktext}
                            url={menuItem.url}
                            color={!disableScroll || onWhiteBackground ? "darkerGreen" : "white"}
                            internallink
                            underlineOnHover
                          />
                        </Container>
                      );
                    }
                    return null;
                  })}
                </Container>
              )}
              <Container>
                <LogoButton
                  userScrolled={userScrolled}
                  headerData={headerData}
                  isGreenLogo={isGreenLogo}
                  mobileMenuOpen={mobileMenuOpen}
                  disableScroll={disableScroll}
                />
              </Container>
              <Container
                width="50%"
                margin={!disableScroll ? "10px 30px 10px 0px" : "20px 30px 20px 0px"}
                tabletMargin={!disableScroll ? "10px 3%" : "30px 3%"}
                transitionAll={0.2}
                justifyContent="flex-end"
              >
                <HeaderButtons userScrolled={isGreenLogo} justifyContent="flex-end" />
              </Container>
            </Container>
          </AnimationContainer>
        </Container>
      )}
      <MobileMenu menuOpen={mobileMenuOpen} />
    </>
  );
};

export default Header;
